import f00 from "./frame_00_delay-0.01s.gif";
import f01 from "./frame_01_delay-0.01s.gif";
import f02 from "./frame_02_delay-0.01s.gif";
import f03 from "./frame_03_delay-0.01s.gif";
import f04 from "./frame_04_delay-0.01s.gif";
import f05 from "./frame_05_delay-0.01s.gif";
import f06 from "./frame_06_delay-0.01s.gif";
import f07 from "./frame_07_delay-0.01s.gif";
import f08 from "./frame_08_delay-0.01s.gif";
import f09 from "./frame_09_delay-0.01s.gif";
import f10 from "./frame_10_delay-0.01s.gif";
import f11 from "./frame_11_delay-0.01s.gif";
import f12 from "./frame_12_delay-0.01s.gif";
import f13 from "./frame_13_delay-0.01s.gif";
import f14 from "./frame_14_delay-0.01s.gif";
import f15 from "./frame_15_delay-0.01s.gif";
import f16 from "./frame_16_delay-0.01s.gif";
import f17 from "./frame_17_delay-0.2s.gif";
import f18 from "./frame_18_delay-0.2s.gif";
import f19 from "./frame_19_delay-0.2s.gif";
import f20 from "./frame_20_delay-0.2s.gif";
import f21 from "./frame_21_delay-0.2s.gif";

export default [
    f00,
    f01,
    f02,
    f03,
    f04,
    f05,
    f06,
    f07,
    f08,
    f09,
    f10,
    f11,
    f12,
    f13,
    f14,
    f15,
    f16,
    f17,
    f18,
    f19,
    f20,
    f21,
];
